import type { MotorcycleDatabase } from '@/types/supabaseMotorcycle'
import type { FilterDatabase } from '@/types/supabaseFilter'

export default defineNuxtRouteMiddleware((_to, _from) => {
  const {
    selectManufacturer,
    selectModel,
  } = storeToRefs(useSearchMotorcycleStore())

  type Manufacturers = FilterDatabase['filter']['Tables']['manufacturers']['Row'] | null
  type Models = MotorcycleDatabase['motorcycle']['Tables']['vehicleModel']['Row'] | null

  const resetPages = (selectSearch: Ref<Manufacturers | Models>) => {
    selectSearch.value = null
  }

  const clearSearch = () => {
    resetPages(selectManufacturer)
    resetPages(selectModel)
  }

  clearSearch()
})
